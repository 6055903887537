























import { Research, Result } from '@app/models';
import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';
import {
  Block,
  BlockElement,
  FlowTree,
  Module,
  Reference,
} from '@bcase/module-editor';
import * as BCE from '@bcase/module-editor';
import fb from 'firebase/app';
import _ from 'lodash';

import { ModuleModule } from '../../store/modules/module-module';
import { respondent } from '../../store/modules/respondent-module';

@Component
export default class CompleteResearch extends Vue {
  @Prop() elements?: any;
  @Prop() research?: Research;
  @Prop() progression?: number;
  @Prop() module!: Module;
  @Prop() block!: Block;
  @Prop() target?: string;
  public respondent = respondent;
  // public timer: any = undefined;
  public answersGiven: any = {};

  public get i18nLanguage() {
    return this.respondent.language !== this.$FEATURES.languages[0]
      ? this.respondent.language
      : undefined;
  }

  public get coreValues() {
    return this.getValue('core-value');
  }

  public get elementsReq() {
    return this.elements.filter((element: any) => {
      return element.required;
    }).length;
  }

  public get languages() {
    return this.$FEATURES.languages.map(key => ({
      key,
      label: this.$t('language-' + key),
    }));
  }

  public get mockValues() {
    return this.getValue('mock-core-value');
  }

  public get products() {
    return this.getValue('products');
  }

  public get services() {
    return this.getValue('services');
  }

  public get strategicValues() {
    return this.getValue('strategic-value');
  }

  public created() {
    this.watchElement(this.elements);
  }

  public getElementData(element: BlockElement) {
    if (
      this.strategicValues &&
      element.metadata &&
      (element.metadata.dashboard === 'strategic-values' ||
        element.metadata.dashboard === 'missing-values')
    ) {
      const options = this.strategicValues
        .filter(v => !!v)
        .map(val => ({ text: val }));
      return this.parseElementData({ ...element, options } as any);
    }

    if (
      this.coreValues &&
      element.metadata &&
      (element.metadata.dashboard as any) === 'core-value-1'
    ) {
      const combinedValues = [...this.coreValues, ...this.mockValues];
      const options = combinedValues
        .filter(v => !!v)
        .map(val => ({ text: val }));
      return this.parseElementData({ ...element, options } as any);
    }

    if (
      this.coreValues &&
      element.metadata &&
      (element.metadata.dashboard as any) === 'core-value-2'
    ) {
      const rows = [...this.coreValues]
        .filter(v => !!v)
        .map(val => ({ text: val }));
      return this.parseElementData({ ...element, rows } as any);
    }

    if (
      this.products &&
      element.metadata &&
      (element.metadata.dashboard as any) === 'products-services-1'
    ) {
      const combinedValues = [...this.products, ...this.services];
      const options = combinedValues
        .filter(v => !!v)
        .map(val => ({ text: val }));
      return this.parseElementData({ ...element, options } as any);
    }

    // if (
    //   this.products &&
    //   element.metadata &&
    //   (element.metadata.dashboard as any) === 'products-services-2'
    // ) {
    //   const combinedValues = [...this.products, ...this.services];
    //   const options = combinedValues
    //     .filter(v => !!v)
    //     .map(val => ({ text: val }));
    //   return { ...element, options };
    // }

    return this.parseElementData(element);
  }

  public parseElementData(element: BlockElement): BlockElement {
    const copy = { ...element };

    if ('text' in copy) (copy as any).text = this.replaceVariables(copy.text);
    if ('question' in copy)
      (copy as any).question = this.replaceVariables(copy.question);
    if ('options' in copy)
      (copy as any).options = this.parseElementOptions(copy.options);
    if ('rows' in copy)
      (copy as any).rows = this.parseElementOptions(copy.rows);
    if ('columns' in copy)
      (copy as any).columns = this.parseElementOptions(copy.columns);

    return copy;
  }

  public parseElementOptions(options: BlockElement.Option[]) {
    return options
      .map(option => {
        const { text, valid } = this.parseText(option.text);
        return valid ? { ...option, text } : undefined;
      })
      .filter(Boolean) as BlockElement.Option[];
  }

  public inputComplete(e: any, result: Result, template: BlockElement) {
    switch (template.type) {
      case 'checkbox': {
        const hasOther =
          template.other &&
          (result.answer.value as { index: number }[]).some(value => {
            return value.index === template.options.length - 1;
          });

        if (template.validation) {
          const max = parseInt(template.validation.split(':')[1], 10);
          return !hasOther && e.target.value.length === max;
        } else if (template.options.length === e.target.value.length) {
          return !hasOther;
        }

        return false;
      }
      case 'icon':
        return true;
      case 'matrix':
        return e.target.value.every((e: string | null) => e !== null);
      case 'radio': {
        if (template.other)
          return result.answer.value.index !== template.options.length - 1;
        return true;
      }
      case 'slider':
        return true;
      default:
        return false;
    }
  }

  public onInput(e: any, template: BlockElement, element: any, index: number) {
    // console.log(e);
    if (e.target.value === null || undefined) return;

    // if (this.timer) {
    //   clearTimeout(this.timer);
    // }

    const value = e.target.value as any;
    const otherExists = 'other' in template && template.other;
    const otherChosen =
      template.type === 'radio'
        ? value.index === template.options.length - 1
        : template.type === 'checkbox'
        ? value.some((v: any) => v.index === template.options.length - 1)
        : false;

    const claims = this.respondent.claims as any;
    const { cid, sub, name, email, user_id } = claims;
    const { gender, rid, target } = this.respondent;
    const nr = this.progression + '.' + index;
    const elementId =
      this.module.name === 'Formule-specifieke vragen'
        ? this.getElementIdOfFsq(element.id, this.respondent.target)
        : element.id;

    const answerData: Result = {
      id: '',
      rid,
      cid,
      sub,
      frm: this.$company.name!,
      nr,
      moduleName: this.getModuleName(),
      target: this.respondent.target,
      route: this.$route.params as any,
      respondent: {
        name,
        email,
        user_id,
        gender,
        target,
      },
      respondentData: this.respondent.respondentData,
      elementId,
      dashboard: (element.metadata && element.metadata.dashboard) || null,
      Yaxis: (element.metadata && element.metadata.Yaxis) || null,
      Xaxis: (element.metadata && element.metadata.Xaxis) || null,
      question: {
        id: element.id,
        questionText: element.question || '',
        options: element.options || null,
        columns: element.columns || null,
        rows: element.rows || null,
        type: element.type,
        min: element.min || null,
        max: element.max || null,
      },
      answer: {
        id: element.id,
        type: element.type,
        value: e.target.value,
        other: otherExists && otherChosen,
        timestamp: fb.firestore.Timestamp.fromDate(new Date()),
      },
      order: {
        module: this.module.metadata.order,
        block: new FlowTree(this.module.flow).getDepth(
          this.block.id,
          this.block.version
        ),
        element: index,
      },
    };

    if (this.inputComplete(e, answerData, template)) {
      this.answersGiven = { ...this.answersGiven, [element.id]: true };
    }

    // const done = ;
    // if (done && template.metadata.dashboard === 'core-value-1')
    //   answerData.locked = true;

    this.saveAnswer(answerData, element.id);
    if (this.allAnwsersGiven(template)) this.$emit('block-done');
  }

  public getModuleName() {
    const { name } = this.module;

    if (!name) return 'Formule specifieke vragen';
    if (this.respondent.target === 'entrepreneurs')
      return name + '(Ondernemers)';
    if (this.respondent.target === 'headquarters')
      return name + '(Kantoormedewerkers)';

    return name;
  }

  public saveAnswer(answer: any, elementId: string) {
    const id = this.getId(elementId);
    answer.id = id;
    this.$firebase
      .col('results')
      .doc(id)
      .set(answer, { merge: true })
      // .then(() => {
      //   this.$bce.message(this.$t('saved'));
      // })
      .catch(console.log);
  }

  public getId(elementId: string) {
    const idExists = this.respondent.results.find((result: any) => {
      if (
        this.respondent.sub === result.sub &&
        elementId === result.question.id
      ) {
        return result.id;
      }
    });
    return idExists ? idExists.id : this.$firebase.generateId();
  }

  public allAnwsersGiven(current: BlockElement) {
    if (current.type === 'slider') return false;

    const questions = this.elements.filter((el: any) => !!el.question);
    return Object.keys(this.answersGiven).length === questions.length;
  }

  public getElementIdOfFsq(elementId: string, target: string) {
    return target === 'entrepreneurs' ? elementId + 'E' : elementId + 'H';
  }

  @Watch('elements')
  public async watchElement(elements: any) {
    if (!Array.isArray(elements)) return;
    await this.$nextTick();

    for (const el of elements) {
      const element = this.$refs[el.id + ''] as any;
      const result = this.respondent.resultById(el.id);

      element[0].value = element[0].value || (result && result.answer.value);
      element[0].disabled = result && result.lock;
    }
  }

  private getValue(key: string): string[] {
    if (!this.research) return [];

    const value = this.i18nLanguage
      ? this.research.i18n &&
        this.research.i18n[this.i18nLanguage] &&
        (this.research.i18n[this.i18nLanguage] as any)[key]
      : (this.research as any)[key];

    return value || [];
  }

  private parseText(text: string) {
    let valid = true;
    let variables: string[] = [];

    const data: any = this.respondent.respondentData || {};
    const next = text
      .replace(/{formule}/g, this.$company.name || '')
      .replace(/{(.*?)}/g, (sub, variable) => {
        variables.push(variable);
        const value = data[variable];
        if (!value) valid = false;
        return value || '';
      });

    return { valid, text: next, variables };
  }

  private replaceVariables(text: string) {
    return this.parseText(text).text;
  }
}
